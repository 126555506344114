.App {
  text-align: center;
}
.ffff{
  max-height: 50px !important;
  min-height: 50px !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* .rrr::-webkit-scrollbar{
  width: 0px !important;
  height: 0px !important;
  visibility: hidden !important;
  display: none !important;
  scrollbar-width: 2px !important;
} */
.scrollnone::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.scrollnone {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}
.scrollnone::-ms-scrollbar {
  width: 0px;
  height: 0px;
}
.App-link {
  color: #61dafb;
}
.hide{
  display: none;
}
/* .interview-model-interview-timing-button{
  back
} */

/* seeker profile Question/Answer tab--------------------------- */
textarea{
  resize: none !important;
}
/* seeker profile Question/Answer tab--------------------------- */

.registered-bussiness-badge{
  --f: 5px; /* control the folded part*/
  --r: 6px; /* control the ribbon shape */
  --t: -5px; /* the top offset */
    position: absolute;
    z-index: 1;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 0 5px var(--f) calc(5px + var(--r));
    clip-path: 
      polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    background: #BD1550;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
   color:#fff;
   font-size: 10px;
}

.share-btn:hover{
  background-color: #FFCB05;
}
.rcsd.rcsd-dropdown{width: 100% !important; height: 45px !important;}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.employer-job-detail-opening-tab-shadow{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
/*------------------------------ Responsive --------------------------------------------------------- */

/* ----------------------------- login ---------------------------- */
@media screen and (max-width: 767px) {
.column-reverse-767{
  display: flex;
  flex-direction: column-reverse;
}
}
/* ----------------------------- login ---------------------------- */


@media screen and (max-width: 500px) {
.change-password-innerdiv{
  width: 90%;
}
}
@media screen and (max-width: 425px) {
.main-center{
  padding: 15px 5px;
}
}
/* @media screen and (max-width: 600px) {
.header-preview-btn{
  display: none;
} */
@media screen and (max-width: 318px) {
.header-preview-btn{
  font-size: 10px;
}

}
/* -------------------------------- Dashboard Two --------------------------------------------------- */



@media screen and (max-width: 1330px) {
  .applicant-opening-card-scroll{
    overflow-x: auto;
    /* overflow-y: hidden; */
    /* z-index: 99999 !important; */
    /* position: absolute; */
  }
}
@media screen and (max-width: 500px) {
  .job-search-model-benefits, .job-listing-model-benefits{
    flex-direction: column;
    gap: 15px;
    align-items: start;
  }
  .job-search-model-benefits-dot, .job-listing-model-benefits-dot{
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .job-search-preview-header, .job-listing-preview-header{
    flex-direction: column;
    gap: 20px;
  }
  
}
@media screen and (max-width: 550px) {
  .job-search-filter-input {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .job-search-wrapper {
    padding: 20px 0px;
  }
}
@media screen and (max-width: 1298px) {
  .jobsearch-filter-span {
    display: none;
  }
  .job-search-search-input, .job-search-location-input{
    border: 1px solid grey;
  }
}
@media screen and (max-width: 1400px) {
  .dashboardTwo-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 1400px) {
  .dashboardTwo-left {
    flex-direction: column;
    width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .dashboardTwo-right {
    width: 100%;
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }
}
@media screen and (max-width: 1400px) {
  .new-connection,
  .job-news
  {
    width: 50%;
    margin: 0;   
  }
}
@media screen and (max-width: 1130px) {
  .dashboardTwo-center
  {
    flex-direction: column;  
    height: 100%;
    margin-top: 15px;
  }
  .job-seeker, .tipsSection{
    width: 100%;
  }
  .dashboardTwo-company-layoffs,
  .dashboardTwo-notification{
    width: 100%;
    margin: 0;
  }
  .dashboardTwo-notification{
    margin-top: 10px;
  }
  .article-section{
    flex-direction: column;
  }
  .article-left,
  .article-right
  {
    width: 100%;
  }
  .article-right
  {
    margin-left: 0;
    margin-top: 10px;
  }
  .dashboardTwo-right{
    flex-direction: column;
  }
  .new-connection,
  .job-news{
    width: 100%;
  }
  }
  /* -------------------------------- Dashboard Two --------------------------------------------------- */

/* -------------------------------- Insights ---------------------------------------------------------- */

@media screen and (max-width: 700px) {
  .analytics-graph-header
  {
   flex-direction: column; 
   justify-items: center;  
   text-align: center;
   width: 100%;
   padding: 0;
   margin: 0;
  }
  .analytics-graph{
    height: 700px;
    width: 100%;
    margin: 0;
    padding-bottom: 80px;
  }
  .analytics-graph{
    width: 100%;
   
  }
  .analytic-btn{
    margin: 10px 0 ;
  }
}
@media screen and (max-width: 1000px) {
  .analytics-graph{
    width: 100%;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

/* -------------------------------- Insights ---------------------------------------------------------- */

/* -------------------------------- Resume ---------------------------------------------------------- */

@media screen and (max-width: 1400px) {
  .resume-upper{
    flex-direction: column;
  }
  .resume-upper-left,
  .resume-upper-right{
    width: 100%;
    height: 600px;
  }
}
@media screen and (max-width: 620px) {
  .resume-upper-left
  {
    width: 100%;
  }
  .resume-upper-left{
    height: 600px;
  }
}

/* -------------------------------- Insights ---------------------------------------------------------- */

/* -------------------------------- Profile preview ---------------------------------------------------------- */

@media screen and (max-width: 1200px) {
  .font-15px-profile-preview{
    font-size: 12px;
  }
}

@media screen and (max-width: 950px) {
  .header-profile-section,
  .header-profile-left
  {
    flex-direction: column;
  }
 .profile-header{
  height: 600px;
 }
 .header-profile-section{
  position: relative;
  z-index: 2;
  margin-bottom: 100px;
  bottom: 100px;
  margin: 0;
  padding: 0;
 }
 .header-profile-left{
  width: 100%;
  justify-content: center;
  align-items: center;
 }
 .header-profile-right{
  margin-top: 15px;
  width: 100%;
  justify-content: start;
  align-items: start;
 }  
}
@media screen and (max-width: 650px) {
.profile-circle{
  width: 180px;
  height: 180px;
}
}
@media screen and (max-width: 500px) {
.profile-circle{
  width: 160px;
  height: 160px;
}
}
@media screen and (max-width: 400px) {
.profile-circle{
  width: 150px;
  height: 150px;
}
}
@media screen and (max-width: 450px) {
  .profile-name{
   font-size: 20px;
   margin-top: 5px;
  }
  .profile-name input{
   font-size: 16px;
  }
  }
@media screen and (max-width: 600px) {
  .profile-preview-banner-btn{
    top: 15px;
  }
  /* .profile-preview-camera-circle{
    width: 35px;
    height: 35px;
  }
  .profile-preview-camera-circle img{
    width: 20px;
  } */
  }
/* -------------------------------- Profile preview ---------------------------------------------------------- */


/* -------------------------------- preview About ---------------------------------------------------------- */
@media screen and (max-width: 900px) {
  .profile-about{
    flex-direction: column;
  }
  .profile-left{
    width: 100%;
  }
  .profile-right{
    width: 100%;
  }
  
}

/* -------------------------------- preview About ---------------------------------------------------------- */


/* -------------------------------- preview Education ---------------------------------------------------------- */
@media screen and (max-width: 600px) {
.education-wrapper,
.skill-wrapper,
.experience-wrapper
{
  flex-direction: column;
}
.education-img-container,
.skill-img-container,
.experience-img-container
{
  width: 100%;
  height: 160px;
  /* background-color: red; */
}
.font-22px{
  font-size: 22px;
}
.font-18px{
  font-size: 18px;
}
.font-15px{
  font-size: 15px;
}
.experience-desc,
.skill-desc,
.education-desc{
  margin: 0;
}
}

/* -------------------------------- preview Education ---------------------------------------------------------- */

/* -------------------------------- Profile ---------------------------------------------------------- */

@media screen and (max-width: 960px) {
 .gapp-5px{
  gap: 10px;
 } 
 .padding-10px{
  padding: 10px 10px;
 }
 }


 /* -------------------------------- Profile ---------------------------------------------------------- */

/* -------------------------------- Notification ---------------------------------------------------------- */

@media screen and (max-width: 700px) {
.notification-card{
flex-direction: column;
text-align: center;
}
}

/* -------------------------------- Notification ---------------------------------------------------------- */

/* -------------------------------- News ---------------------------------------------------------- */
@media screen and (max-width: 700px) {
.padding-10px{
  padding: 10px 10px;
 }
}

/* -------------------------------- News ---------------------------------------------------------- */

/* -------------------------------- Job Search ---------------------------------------------------------- */



@media screen and (max-width: 400px) {
  .joblist-interview-date-time-button{
    width: 100%;
  }
}
@media screen and (max-width: 1650px) {
.job-search-table-box,
.job-listing-table-box
{
  overflow-x: scroll;
}

}


/* -------------------------------- Job Search ---------------------------------------------------------- */

/* -------------------------------- Profile Portfolio ---------------------------------------------------------- */

@media screen and (max-width: 1100px) {
.portfolio-project-upload-section{
  flex-direction: column;
}
.portfolio-project-upload-left,
.portfolio-project-upload-right{
  width: 100%;
}

}
@media screen and (max-width: 550px) {
.portfolio-file-upload-section{
  flex-direction: column;
}

}


/* -------------------------------- Profile Portfolio ---------------------------------------------------------- */

/* -------------------------------- Pagination ---------------------------------------------------------- */

@media screen and (max-width: 800px) {
.pagination-wrapper{
  flex-direction: column-reverse;
  gap: 10px;
}
}

/* -------------------------------- Pagination ---------------------------------------------------------- */

/* employer-dashboard */

@media screen and (max-width: 1110px) {
    .employer-dashboard-section-2{
      flex-direction: column-reverse;
    }
    .employer-dashboard-card-section{
      margin-left: 0px;
    }
    .dashboard-2-2nd-wrapper, .dashboard-two-upper-section-cards{
      padding: 0px;
    }
  
}
/* ------------------------------------job-ads--------------------- */
@media screen and (max-width: 600px) {
.employer-preview-model-header{
  flex-direction: column;
  gap: 15px;
}
.employer-job-post-publish-preview{
  width: 95%;
}
}
/* ------------------------------------job-ads--------------------- */

/* ------------------------------------------------employer-------------------[------------------------------- */


/* ----------------------------------------------- Dashboard ---------------------------------------------------- */

@media screen and (max-width: 1200px) {
.employer-dashboard-layout{
  flex-direction: column-reverse;
}
}
@media screen and (max-width: 480px) {
.dashboard-application-name-box{
  width: 80px;
}
}

/* ----------------------------------------------- Dashboard ---------------------------------------------------- */
/* ----------------------------------------------- job-detail ---------------------------------------------------- */
@media screen and (max-width: 750px) {
.job-detail{
  padding: 0px;
}
}
@media screen and (max-width: 1000px) {
.job-detail-opening-card-header{
  flex-direction: column;
}
.job-detail-opening-card-header-profile{
  margin: auto;
}
}
@media screen and (max-width: 1215px) {
.job-details-interview-modal{
  width: 60%;
}
}
@media screen and (max-width: 1150px) {
.job-details-interview-modal{
  width: 70%;
}
}
@media screen and (max-width: 860px) {
.job-details-interview-modal{
  width: 95%;
}
}
@media screen and (max-width: 640px) {
.interview-component-buttons, .propose-time-date-inputs, .request-availibility-inputs, .fixed-schedule-inputs, .interview-type-buttons, .inperson-inputs, .video-inputs, .phone-inputs, .content-inputs{
  width: 100%;
}
.d-none{
  display: none;
}
}

/* ----------------------------------------------- job-detail ---------------------------------------------------- */
/* ----------------------------------------------- job-ads ---------------------------------------------------- */
@media screen and (max-width: 750px) {
.job-ads{
  padding: 0px;
}
}

/* ----------------------------------------------- job-ads ---------------------------------------------------- */
/* ----------------------------------------------- job-post ---------------------------------------------------- */
@media screen and (max-width: 930px) {
.job-post-publish-overlay-box{
  width: 80%;
}
}
@media screen and (max-width: 580px) {
.job-post-publish-overlay-box{
  width: 90%;
}
}
@media screen and (max-width: 650px) {
.employer-preview-modal-header{
  flex-direction: column;
  gap: 15px;
}
}

/* ----------------------------------------------- job-post ---------------------------------------------------- */

.overlay-color{
  background-color: rgba(0, 0, 0, 0.397);
}

.employer-overlay-none{
  display: none;
}

.jobtypeActive{
  background-color: #FFCB05;
  border: 2px solid black;
}
.fontbold{
  font-weight: bold;
}
.question-display-none{
  display: none;
}
.disqualifiers-checkbox{
  border: 2px solid black !important;
  width: 20px !important;
  height: 20px !important;
}
.disqualifiers-radio{
  width: 23px !important;
  height: 23px !important;
  border-radius: 50% !important;
}

/* ---------Range style -------------- */

.range-input {
  -webkit-appearance: none;
  appearance: none;
  /* width: 100%; */
  height: 18px;
  /* background: #D9D9D9; */
  background: transparent;
  outline: none;
  border-radius: 4px;
  /* overflow: hidden; */
}

.range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #FFCB05;
  cursor: pointer;
  border-radius: 50% !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  /* padding-bottom: 5px; */
}

.range-input::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #FFCB05;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.range-input::-ms-thumb {
  width: 15px;
  height: 15px;
  background: #FFCB05;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0);
}

.range-input::-webkit-slider-runnable-track {
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  /* opacity: 0.1; */
}

.range-input::-moz-range-track {
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  background-color: transparent;
  border-radius: 4px;
}

.range-input::-ms-track {
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  border-color: transparent;
  color: transparent;
}
/* ---------Range style -------------- */

/* ------------------------------------------------- jobPost Responsive --------------------------------------------- */
@media screen and (max-width: 1650px) {

  /* .font-1600{
    font-size: 10px !important;
  } */



}

@media screen and (max-width: 1450px) {

  .range-wrapper{
    flex-direction: column;
  }
  .range-right,.range-left{
    width: 100%;
  }
 
  

}
@media screen and (max-width: 767px) {
  .padding-remove{
    padding: 0px 0px;
  }

}
@media screen and (max-width: 900px) {
  .switch-wrapper{
    flex-direction: column;
  }
  .switch-right, .switch-left{
    width: 100%;
  }
  .switch-text-1000{
    font-size: 18px;
  }
  .switch-text-900{
    font-size: 16px;
  }
  .add-skill-input{
    width: 100%;
  }
  .add-skill-wrapper{
    margin-top: 10px;
  }
 
  .range-input{
    width: 180px;

  }
  .range-input-wrapper{
    width: 180px;
  }
  .range-wrapper-main{
    width: 180px;
  }
  .disqualifiers-wrapper{
    padding-left: 0px;
  }
  .radio-input-wrapper{
    flex-direction: column;
    gap: 10px;
  }
  .radio-input-right.radio-input-left,.radio-input-left-select,.radio-input-right-select{
    width: 100% !important;
  }
  .radio-input-left,.radio-input-right{
    width: 100%;
  }
  .radio-input-right-select-bottom{
    width: 100%;
  }

  .radio-display-none{
    display: none;
  }
  .radio-padding-left{
    padding-left: 30px;
  }
  .disqualifiers-checkbox{
    height: 13px !important;
    width: 13px !important;
  }
  
  .height{
    max-height: 30px !important;
  }
  }
  @media screen and (max-width: 768px) {
    .job-post-wrapper{
      margin: 0px !important;
    }
    .skilladd-section{
      width: 100%;
    }
    }
  @media screen and (max-width: 767px) {
    .range-wrapper{
      padding: 0px 0px;
    }
  }
  @media screen and (max-width: 425px) {
    .range-input{
      width: 150px;
    }
    .range-input-wrapper{
      width: 150px;
    }
    .range-wrapper-main{
      width: 150px;
    }
  }
 
  



/* ------------------------------------------------- jobPost Responsive --------------------------------------------- */
/* ------------------------------------------------- company-profile --------------------------------------------- */
@media screen and (max-width: 900px) {
  .company-profile-img{
    height: 100px;
    margin: 20px 0px;
  }
}
@media screen and (max-width: 768px) {
.company-profile-second-wrapper{
  padding: 0px !important;
}
}

/* ------------------------------------------------- company-profile --------------------------------------------- */
@media screen and (max-width: 768px) {
  .asas{
    width: 100%;
  }
  .pay-input-text-display-none{
    display: none;
  }
  .pay-scroll{
    height: 80vh;
    overflow: scroll;
  }
  
  }
  /* ------------------ pay component------------------ */
  @media screen and (max-width: 768px) {
  .question-inner-div{
    width: 100% !important;
    height: 100vh !important;
    position: fixed;
    top: 0px;
    left: 0px;
    border-radius: 0px;
  }
  /* .dd{
    min-height: 5px;
  } */
}

  /* ------------------ pay component------------------ */



  /* ----------------- */
  /* @media screen and (max-width: 768px){
    td{
        display: block;
        padding: 10px 0;
        width: 100% !important;
    }
    tr{
      width: 100% !important;
    }
    th{
      display: none;
  }
  } */

  /* -----------------table-------------------- */
  tr:hover {
    /* Remove or ensure no filter causing blur */
    /* filter: none; */
    /* Remove or ensure no transform causing blur */
    /* transform: 0; */
    /* transition: 0.2s; */
    /* scale: 1.01; */
    /* Remove or ensure no opacity causing blur */
    /* opacity: 1; */
}
  /* -----------------table-------------------- */

  .display-nones{
    /* transform: translateY(-100%); */
    /* opacity: 0; */
    /* transition: 0.5s; */
    /* background-color: red !important; */
    display: none;
    /* z-index: -10; */
  }


 /* table{
  table-layout: fixed;
 } */
  .option-height {
    margin: 20px !important;
    line-height: 30px !important;
    height: 30px !important;
    padding: 40pxs;
  }
  .ppp{
    max-height: 5px !important;
  }

  @media screen and (max-width: 1500px) {
    .table-text{
      font-size: 13px;

    }
  }

  .toggle-label {
    position: relative;
  }
  .toggle-label:before {
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
    display: block;
    content: "";
    width: 1.45rem;
    height: 1.45rem;
    border-radius: 9999%;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40%;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23a0aec0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');
    transition: transform 0.15s cubic-bezier(0, 0, 0.2, 1);
    transform: translateX(0);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .toggle-checkbox:checked + .toggle-label:before {
    transform: translateX(1.5rem);
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23a0aec0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>');
  }
  
  .toggle.slim .toggle-label:before {
    top: 0rem;
    left: 0;
  }
  .toggle.slim .toggle-checkbox:checked + .toggle-label:before {
    transform: translateX(1.75rem);
  }
  .toggle.colour .toggle-label {
    background-color: #FFCB05;
    top: -0.05rem;
    left: -0.05rem;
  }
  .toggle.colour .toggle-checkbox:checked + .toggle-label {
    background-color: #FFCB05;
  }